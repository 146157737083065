<template>
<div class="mobile-page-layout">

    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                
                <router-link v-if="listShow" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>
                
                <div v-if="!listShow" class="mobile-page-header-icon pointer back-icon" @click.prevent="showSidebar('list')">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>

                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path class="fa-primary" d="M60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8L60.21 448.8z" />
                        <path class="fa-secondary" d="M579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24L579.8 63.24z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    <slot v-if="listShow">بوک‌مارک‌ها</slot>
                    <span v-if="!listShow && activeFavorite">{{ activeFavorite.title }}</span>
                </div>  
            </div>
            <div class="mobile-page-header-actions">
                <div v-if="!widgetLoad && activeFavorite && !listShow" class="mobile-page-header-icon pointer" @click.prevent="open_favorite_add_modal()">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>

                <div v-if="listShow" class="mobile-page-header-icon pointer" @click="modal = !modal">
                    <i class="header-uil-icon uil uil-plus-circle"></i>
                </div>

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list class="header-help-list">
                                <v-list-item @click.prevent="get_modal('search')" v-if="listShow && favorites.length">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click.prevent="get_modal('search2')" v-if="!listShow &&activeFavorite.links.length">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>
                                
                                <v-list-item @click.prevent="get_modal('help')">
                                    <v-list-item-title>راهنما</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>


    <slot v-if="widgetLoad">
        <div class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>
    <slot v-else>

       <!-- صفحه فهرست -->

       <slot v-if="listShow">

            <div v-if="favorites.length  && search" class="mobile-search-box">
                <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در فهرست‌ها" id="newFavorite" v-model="searchQuery"></v-text-field>
            </div>

            <div v-if="favorites.length == 0" class="noWidget noContent">
                <div>
                    <i class="uil uil-list-ui-alt noContent-icon" aria-hidden="true"></i>
                    <p class="noContent-title">فهرستی برای نمایش وجود ندارد</p>
                    <div class="tgju-btn  m-top tgju-btn-primary" @click="modal = !modal"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد فهرست</div>
                </div>
            </div>

            <table class="widget-table widget-table-lg" v-if="filteredList.length">
                <thead>
                    <tr>
                        <th>عنوان</th>
                        <th class="w60 text-center">ویرایش</th>
                        <th class="w60 text-center">حذف</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pointer" v-for="(favorite, index) in filteredList" :key="index" >
                        <td class="border-l bg-gray" @click="openFavorite(favorite.id);"> {{ favorite.title }}</td>
                        <td class="w60 text-center border-l"><i class="uil uil-edit-alt table-icons pointer" @click="open_favorite_edit_modal(favorite.id)"></i></td>
                        <td class="w60 text-center"><i class="uil uil-trash table-icons red-color pointer" @click="removeFavorite(favorite.id)"></i></td>
                    </tr>
                </tbody>
            </table>

            <!-- <div class="add-new-list-mobile">
                <div class="mobile-app-input-add btn-w100" @click="modal = !modal">افزودن فهرست جدید</div>
            </div> -->

            <v-overlay class="modal-overlay" :value="modal" @click="modal = false"></v-overlay>
            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="modal">
                        <v-sheet>
                            <div v-if="listShow" class="add-new-list-mobile in-modal">
                                <v-text-field class="mobile-app-input" label="عنوان فهرست" id="newFavorite" v-model="newFavorite" @keyup.enter="addFavorite()"></v-text-field>
                                <div class="mobile-app-input-add" @click="addFavorite()">افزودن</div>
                            </div>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </slot>

        <!-- صفحه لینک ها -->

        <slot v-if="!listShow">
            <div class="mobile-search-box" v-if="activeFavorite.links.length  && search2">
                <v-text-field class="mobile-app-input mobile-app-input-full" label="جستجو در لینک‌ها" id="newFavorite" v-model="searchQueryLink"></v-text-field>
            </div>

            <div v-if="activeFavorite.links.length == 0" class="noWidget noContent">
                <div>
                    <i class="uil uil-link noContent-icon" aria-hidden="true"></i>
                    <p class="noContent-title">مقداری برای نمایش وجود ندارد</p>
                    <a href="" class="tgju-btn m-top tgju-btn-primary" @click.prevent="open_favorite_add_modal()"><i class="uil uil-plus-circle" style="display: inline-block;"></i> افزودن لینک</a>
                </div>
            </div>

            <slot v-if="activeFavorite">
                <table v-if="filteredLinks.length" class="widget-table widget-table-lg">
                    <thead>
                        <tr>
                            <th>عنوان لینک</th>
                            <th class="w60 text-center">ویرایش</th>
                            <th class="w60 text-center">حذف</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" v-for="link in filteredLinks" :key="link.id" >
                            <td class="border-l bg-gray" @click="openLink(link.id)"> {{ link.title }}</td>
                            <td class="w60 text-center border-l" @click="open_favorite_add_modal(link.id)"><i class="uil uil-edit-alt table-icons pointer"></i></td>
                            <td class="w60 text-center" @click="removeFavoriteLink(link.id)"><i class="uil uil-trash table-icons red-color pointer"></i></td>
                        </tr>
                    </tbody>
                </table>
            </slot>
            <slot v-else>
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </slot>
        </slot>

    </slot>

</div>
</template>

<style lang="scss">
    .widget-min-box-content {
        .l-title {
            display: flex;
        }

        .edit-favorite {
            font-size: 14px;
            cursor: pointer;
            margin-right: -3px;
        }
    }
</style>

<script>
// این کامپوننت دارای اجزا و کنترلر های مربوط به نشان شده ها یا علاقه مندی ها می باشد
// import draggable from 'vuedraggable'

export default {
    name: 'FavoriteMobile',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        // draggable
    },
    data() {
        return {
            modal: false,
            listShow: true,
            favorites: [],
            activeFavorite: undefined,
            newFavorite: '',
            searchQuery: '',
            searchQueryLink: '',
            responsive: {
                rightSection: true,
                leftSection: false,
                leftPanel: false,
            },
            widgetLoad: true,
            addEditLoading: false,
            search:false,
            search2:false
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                // تزریق داده های ورود به کامپوننت
                this.loadData();
            }
        }, { immediate: true });

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    computed: {

        // فیتلر سازی لیست های علاقه مندی ها
        filteredList() {
            return this.favorites.filter(favorite => {
                return favorite.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
        // فیتلر سازی لینک های و یا ایتم های علاقه مندی ها
        filteredLinks() {
            return this.activeFavorite.links.filter(link => {
                return link.title.toLowerCase().includes(this.searchQueryLink.toLowerCase())
            })
        },
    },
    methods: {
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }else if(name == 'search'){
                this.search = !this.search;
            }else if(name == 'search2'){
                this.search2 = !this.search2;
            }
        },
        // این تابع وظیفه تزریق داده های ورودی به کامپوننت را دارد	
        loadData() {
            this.favorites = this.data.favorites;

            if (this.favorites.length) {
                this.activeFavorite = this.favorites[0];
            }

            this.widgetLoad = false;
        },
        // این متد وظیفه ی حذف یک علاقه مندی و یا نشان شده را دارد
        removeFavorite(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    this.activeFavorite = undefined;

                    let index = this.$helpers.getIndex(this.favorites, id);

                    this.favorites.splice(index, 1);

                    let next_index = index - 1;
                    if (next_index < 0)
                        next_index = 0;

                    this.loadData();
                    // if (this.favorites.length > 0) {
                    //     this.loadData();
                    // }

                    this.$helpers.makeRequest('DELETE', '/bookmark/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "فهرست با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف فهرست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
            this.listShow = true;
        },
        // این متد وظیفه این را دارد پس از کلیک روی علاقه مندی مورد نظر آیتم های آن را لود میکند
        openFavorite(id) {
            let index = this.$helpers.getIndex(this.favorites, id);

            this.activeFavorite = this.favorites[index];
            this.listShow = false;
        },
        // این متد وظیفه ایجاد علاقه مندی جدید را دارد و ارسال درخواست به سرور جهت ثبت در دیتابیس را دارد
        addFavorite() {
            if (!this.newFavorite) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان فهرست را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }

            if (!this.newFavorite) {
                return;
            }

            let id = 'id-' + new Date().getTime();
            this.addEditLoading = true;

            this.favorites.push({
                id,
                title: this.newFavorite,
                links: []
            });

            let api_data = {
                title: this.newFavorite,
                links: null,
            };

            this.$helpers.makeRequest('POST', '/bookmark/save', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let inserted_id = api_response.data.response.id;

                    this.favorites.forEach((favorite, index) => {
                        if (favorite.id == id) {
                            favorite.id = inserted_id;
                        }
                    });
                    this.$swal.fire({
                        icon: "success",
                        title: "فهرست با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                    this.modal = false;
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد فهرست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                    this.modal = false;
                }
            });
            this.newFavorite = '';
            // this.openFavorite(id);
        },
        // این متد جهت هندل سورت کردن و ترتیب قرار گیری ایتم های لیست علاقه مندی ها استفاده میشود
        saveFavoritesSort() {
            let sort = [];
            this.favorites.forEach(favorite => {
                sort.push(favorite.id);
            });

            let api_data = {
                sort: JSON.stringify(sort),
            };

            this.$helpers.makeRequest('POST', '/bookmark/sort', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'ذخیره',
                        message: 'مرتب سازی با موفقیت ذخیره شد',
                    });
                } else {
                    Vue.prototype.$toast.warn({
                        title: 'ذخیره',
                        message: 'مشکلی در مرتب سازی رخ داده است',
                    });
                }
            });
        },
        // این متد برای ذخیره سازی ترتیب لینک های علاقه مندی استفاده میشود
        saveFavoriteLinkSort() {
            this.saveFavoriteLink();
        },
        // این متد برای حذف یک لینک علاقه مندی استفاده میشود
        removeFavoriteLink(id) {
            let index = this.$helpers.getIndex(this.activeFavorite.links, id);
            this.activeFavorite.links.splice(index, 1);
            this.saveFavoriteLink('delete');
        },
        // این متد برای ذخیره سازی یک لینک در علاقه مندی انتخاب شده و مورد نظر استفاده میشود
        saveFavoriteLink(action = null) {
            let api_data = {
                links: JSON.stringify(this.$helpers.unbindObject(this.activeFavorite.links)),
            };

            this.$helpers.makeRequest('POST', '/bookmark/link-save/' + this.activeFavorite.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    if (action == 'editLink') {
                        this.$swal.fire({
                            icon: "success",
                            title: "لینک با موفقیت ویرایش شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else if (action == 'addLink') {
                        this.$swal.fire({
                            icon: "success",
                            title: "لینک با موفقیت ایجاد شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else if (action == 'delete') {
                        this.$swal.fire({
                            icon: "success",
                            title: "لینک با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else {
                    if (action == 'editLink') {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ویرایش لینک رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else if (action == 'addLink') {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ایجاد لینک رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else if (action == 'delete') {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف لینک رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                }
            });
        },
        // این متد پس از کلیک روی لینک مورد نظر آن را در تب جدید باز میکند
        openLink(id) {
            let index = this.$helpers.getIndex(this.activeFavorite.links, id);
            window.open(this.activeFavorite.links[index].url);
        },
        // این متد وظیفه هندل کردن مودال تنظیمات علاقه مندی در ویجت داشبورد است
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/Favorite.vue',
                config: {
                    data: {
                        title: this.widget.title,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget);
            });
        },
        // این متد وظیفه ی باز کردن مودال و هندل کردن کال بک مودال جهت ایجاد لینک جدید در علاقه مندی ها می باشد
        open_favorite_add_modal(id = null) {
            let callbackEvent = this.$router.currentRoute.name + '_AddLinks_' + new Date().getTime();

            let action = (id) ? 'edit' : 'add';
            let link = null;

            if (id) {
                let index = this.$helpers.getIndex(this.activeFavorite.links, id);
                link = this.activeFavorite.links[index];
            }

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'FavoriteAdd.vue',
                config: {
                    title: (action == 'edit') ? 'ویرایش لینک' : 'افزودن لینک',
                    icon: (action == 'edit') ? 'uil uil-edit-alt with-icon' : 'uil uil-plus-circle with-icon',
                    smallModal: true,
                    data: {
                        action,
                        link,
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);
            // هندل کردن کال بک
            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addLink') {
                    this.activeFavorite.links.push({
                        id: 'id-' + new Date().getTime(),
                        title: response.data.link.title,
                        url: response.data.link.url,
                    });
                    this.saveFavoriteLink('addLink');
                } else if (response.action == 'editLink') {
                    link.title = response.data.link.title;
                    link.url = response.data.link.url;

                    this.saveFavoriteLink('editLink');
                }
            });
        },
        // این متد وظیفه ی باز کردن مودال و هندل کردن کال بک مودال جهت ویرایش لینک  در علاقه مندی ها می باشد
        open_favorite_edit_modal(id = 0) {
            if (id > 0) {
                let index = this.$helpers.getIndex(this.favorites, id);
                this.activeFavorite = this.favorites[index];
            }

            let callbackEvent = this.$router.currentRoute.name + '_EditFavorites_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'FavoriteEdit.vue',
                config: {
                    title: 'ویرایش فهرست بوک مارک‌',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        favorite: this.$helpers.unbindObject(this.activeFavorite),
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            // هندل کردن کال بک
            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'editFavorite') {
                    this.activeFavorite.title = response.data.favorite.title;

                    let api_data = {
                        title: this.activeFavorite.title,
                    };
                    // ارسال درخواست به سرور
                    this.$helpers.makeRequest('POST', '/bookmark/edit/' + this.activeFavorite.id, api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            this.$swal.fire({
                                icon: "success",
                                title: "فهرست با موفقیت ویرایش شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در ویرایش فهرست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
            } else if (mode == 2) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
            }
        },
        showSidebar() {
            this.listShow = true;
        },
        hideSidebar() {
            this.listShow = false;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Favorites.vue',
                title: this.widget.title,
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
